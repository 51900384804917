/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import GetHistory from '../../../api/PushLogAPI';
import {
  tuutiHistoryInfo,
  Additional,
  LivingLocation,
  CurrentLocation,
} from '../../../models/tuutiHistoryInfo';
import {
  ERROR_MESSAGE_API,
  TITLE_TEXT,
  PUSH_RESERVATION_INDEX_TEXT,
  RESERVED_AT_TEXT,
  IS_RESERVATION_RESERVATION_CODE,
} from '../../../util/Const';
import { GetTimestamp } from '../GenericMethod';

const NotificationReservationIndex = (props: any) => {
  const {
    history,
    // selectedHistory,
    setSelectedHistory,
  } = props;

  const [historyIndex, setHistoryIndex] = useState<tuutiHistoryInfo[]>([]);

  useEffect(() => {
    GetHistory(IS_RESERVATION_RESERVATION_CODE) // APIから履歴データを取得する非同期関数
      .then((response) => {
        // response.historyのデータ（履歴データ）をtuutiHistoryInfo[]に変換
        const list: tuutiHistoryInfo[] = [];
        for (const historyItem of response.history) {
          // additional 配列が空でなければ key と value を取得（最初の要素のみ）
          const AdditionalItem: Additional = {
            key:
              historyItem.additional.length !== 0
                ? historyItem.additional[0].key
                : '',
            value:
              historyItem.additional.length !== 0
                ? historyItem.additional[0].value
                : '',
          };
          // tuutiHistoryInfo オブジェクトの作成
          // device, gender, age, currentLocation, livingLocation は空配列で初期化
          const historys: tuutiHistoryInfo = {
            id: historyItem.id,
            timestamp: historyItem.timestamp,
            status: historyItem.status,
            succeedDevices: historyItem.succeed_devices,
            failedDevices: historyItem.failed_devices,
            title: historyItem.title,
            message: historyItem.message,
            device: [],
            currentLocation: [],
            livingLocation: [],
            gender: [],
            age: [],
            language: [],
            additional: AdditionalItem,
            isDebug: historyItem.is_debug,
            isReservation: historyItem.is_reservation,
            reservedAt: historyItem.reserved_at,
          };
          // devices が存在すれば、historys.device に追加
          if (historyItem.devices) {
            for (const deviceitem of historyItem.devices) {
              historys.device.push(deviceitem);
            }
          }
          if (historyItem.gender) {
            for (const genderItem of historyItem.gender) {
              historys.gender.push(genderItem);
            }
          }
          if (historyItem.age) {
            for (const ageItem of historyItem.age) {
              historys.age.push(ageItem);
            }
          }
          if (historyItem.language) {
            for (const languageItem of historyItem.language) {
              historys.language.push(languageItem);
            }
          }
          if (historyItem.living_location) {
            for (const livingItem of historyItem.living_location) {
              const living: LivingLocation = {
                regionCode: livingItem.region_code,
                prefectureCode: livingItem.prefecture_code,
              };
              historys.livingLocation.push(living);
            }
          }
          if (historyItem.current_location) {
            for (const currentItem of historyItem.current_location) {
              const current: CurrentLocation = {
                regionCode: currentItem.region_code,
                prefectureCode: currentItem.prefecture_code,
                cityCode: currentItem.city_code,
                villageCode: currentItem.village_code,
              };
              historys.currentLocation.push(current);
            }
          }
          // 最後に、加工したデータを list に追加
          list.push(historys);
        }
        setHistoryIndex(list);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);

  // historyIndex のデータを map() でレンダリング
  if (!history) {
    return null;
  }

  // `reservedAt` を昇順（古いものが上、新しいものが下）にソート
  const sortedHistoryIndex = historyIndex.sort(
    (a: tuutiHistoryInfo, b: tuutiHistoryInfo) => {
      // `reservedAt` が null の場合の処理（null は一番上）
      if (!a.reservedAt) return -1;
      if (!b.reservedAt) return 1;

      return (
        new Date(a.reservedAt).getTime() - new Date(b.reservedAt).getTime()
      );
    }
  );

  // 履歴データをテーブルに表示
  const reservationIndexItem = sortedHistoryIndex.map(
    (historyItem: tuutiHistoryInfo) => {
      return (
        <tr
          key={historyItem.id}
          className="index"
          // クリックすると setSelectedHistory(historyItem) で選択状態にする
          onClick={() => setSelectedHistory(historyItem)}
        >
          <td className="title">{historyItem.title}</td>
          {historyItem.reservedAt ? (
            <td>{GetTimestamp(historyItem.reservedAt)}</td>
          ) : (
            <td />
          )}
        </tr>
      );
    }
  );

  return (
    <article id="history_index" className="rireki">
      <h1>{PUSH_RESERVATION_INDEX_TEXT}</h1>
      <div className="tablearea">
        <table className="rirekiindex_table" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <th scope="col" className="sticky">
                {TITLE_TEXT}
              </th>
              <th scope="col" className="reservation">
                {RESERVED_AT_TEXT}
              </th>
            </tr>
            {reservationIndexItem}
          </tbody>
        </table>
      </div>
    </article>
  );
};
export default NotificationReservationIndex;
