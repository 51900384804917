import React, { useState } from 'react';
import Menu from '../compornent/Menu';
import NotificationReservationIndex from '../compornent/PushReservation/PushReservationIndex';
import NotificationReservationEdit from '../compornent/PushReservation/PushReservationEdit';
import { tuutiHistoryInfo } from '../../models/tuutiHistoryInfo';

const NotificationReservation = (props: any) => {
  const { history } = props;
  const [selectedHistory, setSelectedHistory] =
    useState<tuutiHistoryInfo | null>(null);

  return (
    <div className="main_contents">
      <Menu history={history} />
      <section id="right_contents" className="rireki">
        {selectedHistory === null ? (
          <NotificationReservationIndex
            history={history}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
          />
        ) : (
          <NotificationReservationEdit
            history={history}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
          />
        )}
      </section>
    </div>
  );
};
export default NotificationReservation;
