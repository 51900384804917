// #region import
import axios from 'axios';
import {
  RUNNING_TYPE,
  RUNNING_TYPE_MASTER,
  RUNNING_TYPE_DEVELOP,
  RUNNING_TYPE_LOCAL,
  PUSH_DELETE_API_URL,
  PUSH_DELETE_API_DEV_URL,
  PUSH_DELETE_API_LOCAL_URL,
  API_KEY,
  API_KEY_RC_TEST,
} from '../util/Const';
// #endregion import

// 予約削除API
export const GetPushDelete = async (id: String) => {
  let urlBase: string;
  let apiKey: string;
  switch (RUNNING_TYPE) {
    case RUNNING_TYPE_MASTER:
      urlBase = PUSH_DELETE_API_URL;
      apiKey = API_KEY;
      break;
    case RUNNING_TYPE_DEVELOP:
      urlBase = PUSH_DELETE_API_DEV_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    case RUNNING_TYPE_LOCAL:
      urlBase = PUSH_DELETE_API_LOCAL_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    default:
      urlBase = '';
      apiKey = '';
      break;
  }

  const url = `${urlBase}?id=${id}`;

  if (
    RUNNING_TYPE === RUNNING_TYPE_MASTER ||
    RUNNING_TYPE === RUNNING_TYPE_DEVELOP ||
    RUNNING_TYPE === RUNNING_TYPE_LOCAL
  ) {
    const res = await axios
      .get(url, {
        headers: {
          'x-api-key': apiKey,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
    return res;
  }
  const res = await axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

export default GetPushDelete;
