import axios from 'axios';
import {
  RUNNING_TYPE,
  RUNNING_TYPE_MASTER,
  RUNNING_TYPE_DEVELOP,
  RUNNING_TYPE_LOCAL,
  LOGIN_API_URL,
  LOGIN_API_DEV_URL,
  LOGIN_API_LOCAL_URL,
  API_KEY,
  API_KEY_RC_TEST,
} from '../util/Const';

type LoginDto = {
  id: string;
  password: string;
};

export const PostLogin = async (id: string, password: string) => {
  let url: string;
  let apiKey: string;
  switch (RUNNING_TYPE) {
    case RUNNING_TYPE_MASTER:
      url = LOGIN_API_URL;
      apiKey = API_KEY;
      break;
    case RUNNING_TYPE_DEVELOP:
      url = LOGIN_API_DEV_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    case RUNNING_TYPE_LOCAL:
      url = LOGIN_API_LOCAL_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    default:
      url = '';
      apiKey = '';
      break;
  }

  const dto: LoginDto = {
    id,
    password,
  };

  if (
    RUNNING_TYPE === RUNNING_TYPE_MASTER ||
    RUNNING_TYPE === RUNNING_TYPE_DEVELOP ||
    RUNNING_TYPE === RUNNING_TYPE_LOCAL
  ) {
    const res = await axios
      .post(url, JSON.stringify(dto), {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response.data;
      });
    return res;
  }

  console.info(url);
  console.info(JSON.stringify(dto));
  const res = 'response';
  return res;
};

export default PostLogin;
