/* eslint-disable prettier/prettier */
export type StateType = {
  token: string | null | undefined;
};
export type ActionType = {
  type: 'setToken';
  token: string | null | undefined;
};

export const Reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'setToken':
      return { ...state, idToken: action.token };
    default:
      return state;
  }
};
