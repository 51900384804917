import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MENU_PUSH_LOG_TEXT,
  MENU_PUSH_SEND_TEXT,
  MENU_PUSH_RESERVATION_TEXT,
} from '../../util/Const';
import { context } from '../../App';
import { Logout } from '../../handler/LoginHandler';
import logo from '../../img/logo.png';

const Popup = (props: any) => {
  const { dispatch } = useContext(context);
  const { history, setDisplayPopup } = props;

  const handlePopupClick = (e: any) => {
    if (e.target.closest('#logout_popup')) {
      Logout(dispatch, history);
    } else {
      setDisplayPopup(false);
      document.removeEventListener('click', handlePopupClick);
    }
  };

  document.addEventListener('click', handlePopupClick);

  return (
    <section id="logout_popup">
      <button type="button" className="btn_logout">
        ログアウト
      </button>
    </section>
  );
};

const Menu = (props: any) => {
  const { history } = props;
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);

  const handleNavClick = (e: any) => {
    e.stopPropagation();
    setDisplayPopup(!displayPopup);
  };

  return (
    <section id="left_side">
      <h1>
        <img src={logo} alt="防災クラウド" />
      </h1>
      <div
        className={
          history.location.pathname === '/PushSend' ? 'menu on' : 'menu'
        }
      >
        <Link to="/PushSend">{MENU_PUSH_SEND_TEXT}</Link>
      </div>
      <div
        className={
          history.location.pathname === '/PushLog' ? 'menu on' : 'menu'
        }
      >
        <Link to="/PushLog">{MENU_PUSH_LOG_TEXT}</Link>
      </div>
      <div
        className={
          history.location.pathname === '/PushReservation' ? 'menu on' : 'menu'
        }
      >
        <Link to="/PushReservation">{MENU_PUSH_RESERVATION_TEXT}</Link>
      </div>
      <div id="logout_btn">
        <button
          type="button"
          className="btn"
          onClick={(e) => handleNavClick(e)}
        >
          ログアウト
        </button>
        {displayPopup ? (
          <Popup history={history} setDisplayPopup={setDisplayPopup} />
        ) : null}
      </div>
    </section>
  );
};
export default Menu;
