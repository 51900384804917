import { Dispatch } from 'react';
import PostLogin from '../api/LoginAPI';
import { ActionType } from '../reducer';
import {
  RUNNING_TYPE,
  RUNNING_TYPE_MASTER,
  RUNNING_TYPE_DEVELOP,
  RUNNING_TYPE_LOCAL,
  TESTER_LOGIN_ID,
  TESTER_LOGIN_PASSWORD,
  ERROR_MESSAGE_WRONG_LOGIN,
  ERROR_MESSAGE_ID_LOGIN,
  ERROR_MESSAGE_PASSWORD_LOGIN,
  ERROR_MESSAGE_LOGIN,
  ERROR_CODE_15,
  ERROR_CODE_16,
} from '../util/Const';

const LoginHandler = async (
  dispatch: Dispatch<ActionType>,
  id: string,
  password: string
) => {
  const token = 'on';
  try {
    if (
      RUNNING_TYPE === RUNNING_TYPE_MASTER ||
      RUNNING_TYPE === RUNNING_TYPE_DEVELOP ||
      RUNNING_TYPE === RUNNING_TYPE_LOCAL
    ) {
      const login = await PostLogin(id, password);
      if (login.result) {
        sessionStorage.setItem('token', token);
      }
    } else if (id === TESTER_LOGIN_ID && password === TESTER_LOGIN_PASSWORD) {
      sessionStorage.setItem('token', token);
    } else {
      throw new Error(ERROR_MESSAGE_WRONG_LOGIN);
    }
  } catch (error: any) {
    dispatch({
      type: 'setToken',
      token: undefined,
    });
    if (
      RUNNING_TYPE !== RUNNING_TYPE_MASTER &&
      RUNNING_TYPE !== RUNNING_TYPE_DEVELOP &&
      error.message === ERROR_MESSAGE_WRONG_LOGIN
    ) {
      error.code = ERROR_CODE_15;
    }
    let message = '';
    switch (error.error.code) {
      case ERROR_CODE_15:
        message = ERROR_MESSAGE_ID_LOGIN;
        break;
      case ERROR_CODE_16:
        message = ERROR_MESSAGE_PASSWORD_LOGIN;
        break;
      default:
        message = ERROR_MESSAGE_LOGIN;
        break;
    }
    throw message;
  }
};
export const Logout = (dispatch: Dispatch<ActionType>, history: any) => {
  try {
    history.push('/');
    dispatch({
      type: 'setToken',
      token: undefined,
    });
    sessionStorage.clear();
  } catch (error: any) {
    // eslint-disable-next-line no-alert
    alert(error.message);
  }
};
export default LoginHandler;
