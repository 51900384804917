import React, { useState } from 'react';
import Menu from '../compornent/Menu';
import NotificationHistoryIndex from '../compornent/PushLog/PushLogIndex';
import NotificationHistoryDetail from '../compornent/PushLog/PushLogDetail';
import { tuutiHistoryInfo } from '../../models/tuutiHistoryInfo';

const NotificationHistory = (props: any) => {
  const { history } = props;
  const [selectedHistory, setSelectedHistory] =
    useState<tuutiHistoryInfo | null>(null);

  return (
    <div className="main_contents">
      <Menu history={history} />
      <section id="right_contents" className="rireki">
        {selectedHistory === null ? (
          <NotificationHistoryIndex
            history={history}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
          />
        ) : (
          <NotificationHistoryDetail
            history={history}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
          />
        )}
      </section>
    </div>
  );
};
export default NotificationHistory;
