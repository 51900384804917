/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
// #region import
import React, { useEffect, useState } from 'react';
import GetMunicipality from '../../../api/MunicipalityAPI';
import {
  Prefectures,
  Cities,
  Villages,
  CurrentLocation,
  LivingLocation,
} from '../../../models/tuutiHistoryInfo';
import {
  ERROR_MESSAGE_API,
  TITLE_TEXT,
  MESSAGE_TEXT,
  PARAMETER_TEXT,
  ADDITIONAL_TEXT,
  ADDITIONAL_KEY_TEXT,
  ADDITIONAL_VALUE_TEXT,
  DEVICE_TYPE_TEXT,
  DEVICE_TYPE_ANDROID_TEXT,
  DEVICE_TYPE_IOS_TEXT,
  DEVICE_TYPE_ANDROID_CODE,
  DEVICE_TYPE_IOS_CODE,
  LOCATION_ALL_SELECTED_TEXT,
  LOCATION_OVERSEAS_TEXT,
  LOCATION_UNDEFINED_TEXT,
  CURRENT_LOCATION_TEXT,
  LIVING_LOCATION_TEXT,
  GENDER_TEXT,
  AGE_TEXT,
  LANGUAGE_TEXT,
  IS_DEBUG_TEXT,
  IS_DEBUG_ON_TEXT,
  IS_DEBUG_ON_CODE,
  PUSH_LOG_DETAIL_TEXT,
  SEND_STATUS_TEXT,
  SEND_DATETIME_TEXT,
  SEND_SUCCEED_TEXT,
  SEND_FAILED_TEXT,
  BACK_TO_INDEX_TEXT,
} from '../../../util/Const';
import {
  GetAge,
  GetGender,
  GetLanguage,
  GetTimestamp,
  SendStatus,
} from '../GenericMethod';
// #endregion import

const NotificationHistoryDetail = (props: any) => {
  const { history, selectedHistory, setSelectedHistory } = props;
  // 都道府県リスト
  const [prefectureList, setPrefectureList] = useState<Prefectures[]>([]);
  // 市区町村リスト
  const [cityList, setCityList] = useState<Cities[]>([]);
  // 村域以下リスト
  const [villageList, setVillageList] = useState<Villages[]>([]);
  useEffect(() => {
    GetMunicipality()
      .then((response: any) => {
        const prefectureItems: Prefectures[] = [];
        const cityItems: Cities[] = [];
        const villageItems: Villages[] = [];
        for (const prefecture of response) {
          const prefectureItem: Prefectures = {
            regionCode: prefecture.region_code,
            prefectureCode: prefecture.prefecture_code,
            prefectureName: prefecture.prefecture_name,
          };
          prefectureItems.push(prefectureItem);
          for (const city of prefecture.cities) {
            const cityItem: Cities = {
              regionCode: prefecture.region_code,
              prefectureCode: prefecture.prefecture_code,
              prefectureName: prefecture.prefecture_name,
              cityCode: city.city_code,
              cityName: city.city_name,
            };
            cityItems.push(cityItem);
            if (city.villages) {
              for (const village of city.villages) {
                const villageitem: Villages = {
                  regionCode: prefecture.region_code,
                  prefectureCode: prefecture.prefecture_code,
                  prefectureName: prefecture.prefecture_name,
                  cityCode: city.city_code,
                  cityName: city.city_name,
                  villageCode: village.village_code,
                  villageName: village.village_name,
                };
                villageItems.push(villageitem);
              }
            }
          }
        }
        setPrefectureList(prefectureItems);
        setCityList(cityItems);
        setVillageList(villageItems);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!history) {
    return null;
  }
  const handleClickCloseBtn = () => {
    setSelectedHistory(null);
  };

  // #region デバイスタイプ
  let deviceItem: string = '';
  if (selectedHistory.device) {
    const list: string[] = [];
    if (selectedHistory.device.includes(DEVICE_TYPE_ANDROID_CODE)) {
      list.push(DEVICE_TYPE_ANDROID_TEXT);
    }
    if (selectedHistory.device.includes(DEVICE_TYPE_IOS_CODE)) {
      list.push(DEVICE_TYPE_IOS_TEXT);
    }
    deviceItem = list.join('/');
  }
  // #endregion デバイスタイプ

  // #region 現在地
  let currentList: string = '';
  if (
    Array.isArray(selectedHistory.currentLocation) &&
    selectedHistory.currentLocation.length > 0
  ) {
    const list: string[] = [];
    selectedHistory.currentLocation.forEach((item: CurrentLocation) => {
      if (item.regionCode === 0) {
        list.push(LOCATION_ALL_SELECTED_TEXT);
      } else if (item.regionCode === -1) {
        list.push(LOCATION_UNDEFINED_TEXT);
      } else if (!item.cityCode && !item.villageCode) {
        const prefecture = prefectureList.find(
          (v) =>
            v.regionCode === item.regionCode &&
            v.prefectureCode === item.prefectureCode
        );
        if (prefecture) {
          list.push(prefecture.prefectureName);
        }
      } else if (!item.villageCode) {
        const city = cityList.find(
          (v) =>
            v.regionCode === item.regionCode &&
            v.prefectureCode === item.prefectureCode &&
            v.cityCode === item.cityCode
        );
        if (city) {
          list.push(city.prefectureName + city.cityName);
        }
      } else {
        const village = villageList.find(
          (v) =>
            v.regionCode === item.regionCode &&
            v.prefectureCode === item.prefectureCode &&
            v.cityCode === item.cityCode &&
            v.villageCode === item.villageCode
        );
        if (village) {
          list.push(
            village.prefectureName + village.cityName + village.villageName
          );
        }
      }
    });
    currentList = list.join('/');
  }
  // #endregion 現在地

  // #region 居住地
  let livingList: string = '';
  if (
    Array.isArray(selectedHistory.livingLocation) &&
    selectedHistory.livingLocation.length > 0
  ) {
    const list: string[] = [];
    if (prefectureList) {
      selectedHistory.livingLocation.forEach((item: LivingLocation) => {
        if (item.regionCode === 0) {
          list.push(LOCATION_ALL_SELECTED_TEXT);
        } else if (item.regionCode === 99) {
          list.push(LOCATION_OVERSEAS_TEXT);
        } else if (item.regionCode === -1) {
          list.push(LOCATION_UNDEFINED_TEXT);
        } else {
          const prefecture = prefectureList.find(
            (v) =>
              v.prefectureCode === item.prefectureCode &&
              v.regionCode === item.regionCode
          );
          if (prefecture) {
            list.push(prefecture.prefectureName);
          }
        }
      });
    }
    livingList = list.join('/');
  }
  // #endregion 居住地

  return (
    <article id="history_detail" className="rireki">
      <h1>{PUSH_LOG_DETAIL_TEXT}</h1>
      <div className="busadd_btnarea">
        <button
          type="button"
          className="btn_back"
          onClick={() => handleClickCloseBtn()}
        >
          {BACK_TO_INDEX_TEXT}
        </button>
      </div>
      <div className="margin-top detail">
        <div>
          <dl className="box">
            <dd className="location">{TITLE_TEXT}</dd>
            <dd>{selectedHistory.title}</dd>
          </dl>
        </div>
        <div>
          <dl className="box">
            <dd className="location">{MESSAGE_TEXT}</dd>
            <dd>{selectedHistory.message}</dd>
          </dl>
        </div>
        <div>
          <dl className="box">
            <dd className="location">{SEND_STATUS_TEXT}</dd>
            <dd>{SendStatus(selectedHistory.status)}</dd>
          </dl>
        </div>
        {selectedHistory.timestamp ? (
          <div>
            <dl className="box">
              <dd className="location">{SEND_DATETIME_TEXT}</dd>
              <dd>{GetTimestamp(selectedHistory.timestamp)}</dd>
            </dl>
          </div>
        ) : null}
        {selectedHistory.status !== 0 ? (
          <>
            <div>
              <dl className="box">
                <dd className="location">{SEND_SUCCEED_TEXT}</dd>
                <dd>{selectedHistory.succeedDevices}件</dd>
              </dl>
            </div>
            <div>
              <dl className="box">
                <dd className="location">{SEND_FAILED_TEXT}</dd>
                <dd>{selectedHistory.failedDevices}件</dd>
              </dl>
            </div>
          </>
        ) : null}
        {selectedHistory.additional.key !== '' &&
        selectedHistory.additional.value !== '' ? (
          <div>
            <dl className="box">
              <dd className="location">{ADDITIONAL_TEXT}</dd>
              <div className="margin">
                <span className="bold">{ADDITIONAL_KEY_TEXT}:</span>
                <span>{selectedHistory.additional.key}</span>
              </div>
              <div className="margin">
                <span className="bold">{ADDITIONAL_VALUE_TEXT}:</span>
                <span>{selectedHistory.additional.value}</span>
              </div>
            </dl>
          </div>
        ) : null}
        <div>
          <dl className="box">
            <dd className="location">{PARAMETER_TEXT}</dd>
            <div className="margin">
              {deviceItem !== '' ? (
                <div className="margin">
                  <span className="bold">{DEVICE_TYPE_TEXT}:</span>
                  <span>{deviceItem}</span>
                </div>
              ) : null}
              {currentList !== '' ? (
                <div className="margin">
                  <span className="bold">{CURRENT_LOCATION_TEXT}:</span>
                  <span>{currentList}</span>
                </div>
              ) : null}
              {livingList !== '' ? (
                <div className="margin">
                  <span className="bold">{LIVING_LOCATION_TEXT}:</span>
                  <span>{livingList}</span>
                </div>
              ) : null}
              {Array.isArray(selectedHistory.gender) &&
              selectedHistory.gender.length > 0 ? (
                <div className="margin">
                  <span className="bold">{GENDER_TEXT}:</span>
                  <span>{GetGender(selectedHistory.gender)}</span>
                </div>
              ) : null}
              {Array.isArray(selectedHistory.age) &&
              selectedHistory.age.length > 0 ? (
                <div className="margin">
                  <span className="bold">{AGE_TEXT}:</span>
                  <span>{GetAge(selectedHistory.age)}</span>
                </div>
              ) : null}
              {Array.isArray(selectedHistory.language) &&
              selectedHistory.language.length > 0 ? (
                <div className="margin">
                  <span className="bold">{LANGUAGE_TEXT}:</span>
                  <span>{GetLanguage(selectedHistory.language)}</span>
                </div>
              ) : null}
              {selectedHistory.isDebug === IS_DEBUG_ON_CODE ? (
                <div className="margin">
                  <span className="bold">{IS_DEBUG_TEXT}:</span>
                  <span>{IS_DEBUG_ON_TEXT}</span>
                </div>
              ) : null}
            </div>
          </dl>
        </div>
      </div>
    </article>
  );
};
export default NotificationHistoryDetail;
