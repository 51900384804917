// #region import
import React, { useState, useEffect } from 'react';
import GetMunicipality from '../../../api/MunicipalityAPI';
import {
  MunicipalityCode,
  Municipality,
  City,
  Village,
  PrefectureCode,
} from '../../../models/municipality';
import {
  Prefectures,
  Cities,
  Villages,
} from '../../../models/tuutiHistoryInfo';
import {
  ERROR_MESSAGE_API,
  TITLE_TEXT,
  MESSAGE_TEXT,
  ADDITIONAL_TEXT,
  ADDITIONAL_KEY_TEXT,
  ADDITIONAL_VALUE_TEXT,
  DEVICE_TYPE_TEXT,
  DEVICE_TYPE_ALL_TEXT,
  DEVICE_TYPE_ANDROID_TEXT,
  DEVICE_TYPE_IOS_TEXT,
  DEVICE_TYPE_ANDROID_CODE,
  DEVICE_TYPE_IOS_CODE,
  LOCATION_ALL_SELECTED_TEXT,
  LOCATION_NOT_SELECTED_TEXT,
  LOCATION_SELECT_PREFECTURE_TEXT,
  LOCATION_SELECT_DEFAULT_TEXT,
  LOCATION_SELECT_VILLAGE_TEXT,
  LOCATION_SELECT_CITY_TEXT,
  LOCATION_ALL_TEXT,
  LOCATION_OVERSEAS_TEXT,
  LOCATION_UNDEFINED_TEXT,
  CURRENT_LOCATION_TEXT,
  LIVING_LOCATION_TEXT,
  GENDER_TEXT,
  GENDER_ALL_TEXT,
  GENDER_MALE_TEXT,
  GENDER_FEMALE_TEXT,
  GENDER_OTHER_TEXT,
  GENDER_UNDEFINED_TEXT,
  GENDER_MALE_CODE,
  GENDER_FEMALE_CODE,
  GENDER_OTHER_CODE,
  GENDER_UNDEFINED_CODE,
  AGE_TEXT,
  AGE_ALL_TEXT,
  AGE_10S_TEXT,
  AGE_20S_TEXT,
  AGE_30S_TEXT,
  AGE_40S_TEXT,
  AGE_50S_TEXT,
  AGE_60S_TEXT,
  AGE_UNDEFINED_TEXT,
  AGE_10S_CODE,
  AGE_20S_CODE,
  AGE_30S_CODE,
  AGE_40S_CODE,
  AGE_50S_CODE,
  AGE_60S_CODE,
  AGE_UNDEFINED_CODE,
  LANGUAGE_TEXT,
  LANGUAGE_ALL_TEXT,
  LANGUAGE_JA_TEXT,
  LANGUAGE_EN_TEXT,
  LANGUAGE_KO_TEXT,
  LANGUAGE_ZHS_TEXT,
  LANGUAGE_ZHT_TEXT,
  LANGUAGE_JA_CODE,
  LANGUAGE_EN_CODE,
  LANGUAGE_KO_CODE,
  LANGUAGE_ZHS_CODE,
  LANGUAGE_ZHT_CODE,
  IS_DEBUG_TEXT,
  IS_DEBUG_ON_TEXT,
  IS_DEBUG_OFF_TEXT,
  IS_DEBUG_ON_CODE,
  IS_DEBUG_OFF_CODE,
  IS_RESERVATION_TEXT,
  IS_RESERVATION_IMMEDIATE_TEXT,
  IS_RESERVATION_RESERVATION_TEXT,
  IS_RESERVATION_IMMEDIATE_CODE,
  IS_RESERVATION_RESERVATION_CODE,
  RESERVED_AT_TEXT,
} from '../../../util/Const';
import getBytes from '../../../util/StringUtil';
// #endregion import

/**
 * タイトルコンポーネント
 * @param title
 * @param setTitle
 * @param selectedHistory
 */
export const Title = (props: any) => {
  const { title, setTitle, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory && selectedHistory.title) {
      setTitle(selectedHistory.title);
    }
  }, [selectedHistory]);

  const onChange = (text: string) => {
    setTitle(text);
  };

  return (
    <>
      <dt>{TITLE_TEXT}</dt>
      <dd>
        <input
          type="text"
          value={title}
          className={getBytes(title) > 256 ? 'border_red' : ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
        />
      </dd>
    </>
  );
};

/**
 * メッセージコンポーネント
 * @param message
 * @param setMessage
 * @param selectedHistory
 */
export const Message = (props: any) => {
  const { message, setMessage, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory && selectedHistory.message) {
      setMessage(selectedHistory.message);
    }
  }, [selectedHistory]);

  const onChange = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <>
      <dt>{MESSAGE_TEXT}</dt>
      <dd>
        <textarea
          value={message}
          className={getBytes(message) > 1024 ? 'border_red' : ''}
          onChange={onChange}
        />
      </dd>
    </>
  );
};

/**
 * 付加情報コンポーネント
 * @param addKey keyという名前が使えなかった
 * @param addValue
 * @param setKey
 * @param setValue
 * @param selectedHistory
 */
export const Additional = (props: any) => {
  const { addKey, addValue, setKey, setValue, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory && selectedHistory.additional) {
      setKey(selectedHistory.additional.key);
      setValue(selectedHistory.additional.value);
    }
  }, [selectedHistory]);

  const onChangeKey = (e: any) => {
    setKey(e.target.value);
  };

  const onChangeValue = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <>
      <dt>{ADDITIONAL_TEXT}</dt>
      <dd>
        <label className="additional">
          {ADDITIONAL_KEY_TEXT}
          <input
            type="text"
            value={addKey}
            className={
              getBytes(addKey as string) > 256 ? 'key border_red' : 'key'
            }
            onChange={onChangeKey}
          />
        </label>
        <label className="additional">
          {ADDITIONAL_VALUE_TEXT}
          <input
            type="text"
            value={addValue}
            className={
              getBytes(addValue as string) > 256 ? 'value border_red' : 'value'
            }
            onChange={onChangeValue}
          />
        </label>
      </dd>
    </>
  );
};

/**
 * デバイスタイプコンポーネント
 * @param device
 * @param setDevice
 * @param selectedHistory
 */
export const DeviceType = (props: any) => {
  const { device, setDevice, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory?.device) {
      if (
        selectedHistory.device.includes(DEVICE_TYPE_ANDROID_CODE) &&
        selectedHistory.device.includes(DEVICE_TYPE_IOS_CODE)
      ) {
        setDevice([DEVICE_TYPE_ANDROID_CODE, DEVICE_TYPE_IOS_CODE]);
      } else if (selectedHistory.device.includes(DEVICE_TYPE_ANDROID_CODE)) {
        setDevice([DEVICE_TYPE_ANDROID_CODE]);
      } else if (selectedHistory.device.includes(DEVICE_TYPE_IOS_CODE)) {
        setDevice([DEVICE_TYPE_IOS_CODE]);
      }
    }
  }, [selectedHistory]);

  const onChange = (e: any) => {
    switch (e.target.value) {
      case DEVICE_TYPE_ANDROID_CODE:
        setDevice([DEVICE_TYPE_ANDROID_CODE]);
        break;
      case DEVICE_TYPE_IOS_CODE:
        setDevice([DEVICE_TYPE_IOS_CODE]);
        break;
      default:
        setDevice([DEVICE_TYPE_ANDROID_CODE, DEVICE_TYPE_IOS_CODE]);
        break;
    }
  };

  return (
    <>
      <dt>{DEVICE_TYPE_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="all_device"
            value="all"
            checked={
              device.length === 2 ||
              (device.includes(DEVICE_TYPE_ANDROID_CODE) &&
                device.includes(DEVICE_TYPE_IOS_CODE))
            }
            onChange={onChange}
          />
          <label htmlFor="all_device" className="radio">
            {DEVICE_TYPE_ALL_TEXT}
          </label>
        </label>
        <label>
          <input
            type="radio"
            id="device_android"
            value={DEVICE_TYPE_ANDROID_CODE}
            checked={
              (device as string[]).length === 1 &&
              (device as string[]).includes(DEVICE_TYPE_ANDROID_CODE)
            }
            onChange={onChange}
          />
          <label htmlFor="device_android" className="radio">
            {DEVICE_TYPE_ANDROID_TEXT}
          </label>
        </label>
        <label>
          <input
            type="radio"
            id="device_ios"
            value={DEVICE_TYPE_IOS_CODE}
            checked={
              (device as string[]).length === 1 &&
              (device as string[]).includes(DEVICE_TYPE_IOS_CODE)
            }
            onChange={onChange}
          />
          <label htmlFor="device_ios" className="radio">
            {DEVICE_TYPE_IOS_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * 現在地コンポーネント
 * @param municipality
 * @param currentLocation
 * @param setCurrentLocation
 * @param selectedHistory
 */
export const ReservationCurrentLocation = (props: any) => {
  const { municipality, currentLocation, setCurrentLocation, selectedHistory } =
    props;
  // 都道府県リスト
  const [, setPrefectureList] = useState<Prefectures[]>([]);
  // 市区町村リスト
  const [, setCityList] = useState<Cities[]>([]);
  // 村域以下リスト
  const [, setVillageList] = useState<Villages[]>([]);

  useEffect(() => {
    GetMunicipality()
      .then((response: any) => {
        const prefectureItems: Prefectures[] = [];
        const cityItems: Cities[] = [];
        const villageItems: Villages[] = [];

        response.forEach((prefecture: any) => {
          const prefectureItem: Prefectures = {
            regionCode: prefecture.region_code,
            prefectureCode: prefecture.prefecture_code,
            prefectureName: prefecture.prefecture_name,
          };
          prefectureItems.push(prefectureItem);

          prefecture.cities.forEach((city: any) => {
            const cityItem: Cities = {
              regionCode: prefecture.region_code,
              prefectureCode: prefecture.prefecture_code,
              prefectureName: prefecture.prefecture_name,
              cityCode: city.city_code,
              cityName: city.city_name,
            };
            cityItems.push(cityItem);

            if (city.villages) {
              city.villages.forEach((village: any) => {
                const villageItem: Villages = {
                  regionCode: prefecture.region_code,
                  prefectureCode: prefecture.prefecture_code,
                  prefectureName: prefecture.prefecture_name,
                  cityCode: city.city_code,
                  cityName: city.city_name,
                  villageCode: village.village_code,
                  villageName: village.village_name,
                };
                villageItems.push(villageItem);
              });
            }
          });
        });

        setPrefectureList(prefectureItems);
        setCityList(cityItems);
        setVillageList(villageItems);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);

  useEffect(() => {
    if (
      selectedHistory?.currentLocation &&
      Array.isArray(selectedHistory.currentLocation)
    ) {
      const updatedLocations: MunicipalityCode[] =
        selectedHistory.currentLocation.map(
          (location: {
            regionCode: any;
            prefectureCode: any;
            cityCode: any;
            villageCode: any;
          }) => ({
            region_code: location.regionCode ?? 0,
            prefecture_code: location.prefectureCode ?? 0,
            city_code: location.cityCode ?? 0,
            village_code: location.villageCode ?? 0,
          })
        );

      setCurrentLocation(updatedLocations);
    }
  }, [selectedHistory]);

  // #region 選択済み現在地
  const selectedItems = currentLocation.map(
    (location: MunicipalityCode, i: Number) => {
      // regionが0だったら「すべて(未設定含む)」
      if (location.region_code === 0) {
        return (
          <span key="0,0,0,0">
            {i.valueOf() > 0 ? '/' : null}
            {LOCATION_ALL_SELECTED_TEXT}
          </span>
        );
      }
      // regionが-1だったら「未設定」
      if (location.region_code === -1) {
        return (
          <span key="-1,0,0,0">
            {i.valueOf() > 0 ? '/' : null}
            {LOCATION_UNDEFINED_TEXT}
          </span>
        );
      }
      // 都道府県
      const selectedPref = municipality.find(
        (item: Municipality) =>
          item.region_code === location.region_code &&
          item.prefecture_code === location.prefecture_code
      );
      let name = selectedPref ? selectedPref.prefecture_name : '';
      // 市区町村
      let selectedCity: City | undefined;
      if (selectedPref && location.city_code !== 0) {
        selectedCity = selectedPref.cities.find(
          (item: City) => item.city_code === location.city_code
        );
        name += selectedCity ? selectedCity.city_name : '';
      }
      // 村域以下
      if (selectedCity && location.village_code !== 0) {
        const selectedVillage = selectedCity.villages.find(
          (item: Village) => item.village_code === location.village_code
        );
        name += selectedVillage ? selectedVillage.village_name : '';
      }
      if (name === '') {
        return null;
      }
      return (
        <span
          key={`${location.region_code},${location.prefecture_code},${location.city_code},${location.village_code}`}
        >
          {i.valueOf() > 0 ? '/' : null}
          {name}
        </span>
      );
    }
  );
  // #endregion 選択済み現在地

  // #region 都道府県
  const [isOpenPref, setIsOpenPref] = useState<boolean>(false);
  const [isAllPref, setIsAllPref] = useState<boolean>(true);
  const [checkedPref, setCheckedPref] = useState<MunicipalityCode[]>([]);

  useEffect(() => {
    if (
      selectedHistory?.currentLocation &&
      Array.isArray(selectedHistory.currentLocation)
    ) {
      const updatedLocations: MunicipalityCode[] =
        selectedHistory.currentLocation.map(
          (location: {
            regionCode: any;
            prefectureCode: any;
            cityCode: any;
            villageCode: any;
          }) => ({
            region_code: location.regionCode ?? 0,
            prefecture_code: location.prefectureCode ?? 0,
            city_code: location.cityCode ?? 0,
            village_code: location.villageCode ?? 0,
          })
        );

      // 都道府県のみを取得（市区町村を含まない）
      const onlyPrefectures = updatedLocations.filter(
        (location) => location.city_code === 0 && location.village_code === 0
      );

      setCurrentLocation(updatedLocations);
      setCheckedPref(updatedLocations);

      // すべての都道府県が選択されているか判定
      if (
        onlyPrefectures.length > 0 &&
        onlyPrefectures.some(
          (location) =>
            location.region_code === 0 && location.prefecture_code === 0
        )
      ) {
        setIsAllPref(true);
      } else {
        setIsAllPref(false);
      }
    }
  }, [selectedHistory]);

  const openPref = () => {
    setIsOpenPref(true);
  };

  const closePref = () => {
    setIsOpenPref(false);
  };

  const onClickAllPref = () => {
    const before = isAllPref;
    const selected: MunicipalityCode[] = [];
    // 選択済み現在地
    currentLocation.forEach((item: MunicipalityCode) => {
      // すべてを解除した時
      // すべてを選択した時で都道府県じゃない地点
      if (
        (before || (!before && item.city_code !== 0)) &&
        item.region_code !== 0
      ) {
        selected.push(item);
      }
    });
    // すべてを選択した時
    // 選択済み現在地の末尾にすべて(都道府県)を追加
    if (!before) {
      selected.push({
        region_code: 0,
        prefecture_code: 0,
        city_code: 0,
        village_code: 0,
      });
    } else {
      // すべてを解除した時
      // チェックがついた都道府県を選択済み現在地に追加
      checkedPref.forEach((item: MunicipalityCode) => {
        selected.push(item);
      });
    }
    setIsAllPref(!before);
    setCurrentLocation(selected);
  };

  const onChangeCheckedPref = (e: any) => {
    const selected: MunicipalityCode[] = [];
    const checked: MunicipalityCode[] = [];
    // 更新しない選択済み現在地
    currentLocation.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
          e.target.value &&
        item.region_code !== 0
      ) {
        selected.push(item);
      }
    });
    // 更新しないチェックボックス
    checkedPref.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
        e.target.value
      ) {
        checked.push(item);
        // すべてから変更する場合
        if (isAllPref) {
          selected.push(item);
        }
      }
    });
    const value: string[] = e.target.value.split(',');
    if (e.target.checked) {
      // 更新する選択済み現在地
      selected.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
      // 更新するチェックボックス
      checked.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
    }
    // すべての選択を解除
    setIsAllPref(false);
    // 選択済み現在地の更新
    setCurrentLocation(selected);
    // チェックボックスの更新
    setCheckedPref(checked);
  };

  const PrefCheckboxItems = municipality.map(
    (pref: Municipality, i: Number) => (
      <label key={`${pref.region_code},${pref.prefecture_code}`}>
        <input
          type="checkbox"
          id={`current_location_pref_${i}`}
          value={`${pref.region_code},${pref.prefecture_code},0,0`}
          checked={checkedPref.some(
            (checked) =>
              checked.region_code === pref.region_code &&
              checked.prefecture_code === pref.prefecture_code
          )}
          onChange={onChangeCheckedPref}
        />
        <label
          htmlFor={`current_location_pref_${i}`}
          className={!isAllPref ? 'checkbox location' : 'checkbox location off'}
        >
          {pref.prefecture_name}
        </label>
      </label>
    )
  );
  // #endregion 都道府県

  // #region  市区町村
  const [isOpenCity, setIsOpenCity] = useState<boolean>(false);
  const [citySelectPref, setCitySelectPref] = useState<string>('');
  const [checkedCity, setCheckedCity] = useState<MunicipalityCode[]>([]);

  useEffect(() => {
    if (
      selectedHistory?.currentLocation &&
      Array.isArray(selectedHistory.currentLocation)
    ) {
      const updatedLocations: MunicipalityCode[] =
        selectedHistory.currentLocation.map(
          (location: {
            regionCode: any;
            prefectureCode: any;
            cityCode: any;
            villageCode: any;
          }) => ({
            region_code: location.regionCode ?? 0,
            prefecture_code: location.prefectureCode ?? 0,
            city_code: location.cityCode ?? 0,
            village_code: location.villageCode ?? 0,
          })
        );

      // 市区町村のみを取得
      const onlyCities = updatedLocations.filter(
        (location) => location.city_code !== 0
      );

      setCurrentLocation(updatedLocations);
      setCheckedCity(onlyCities);
    }
  }, [selectedHistory]);

  const openCity = () => {
    setIsOpenCity(true);
  };

  const closeCity = () => {
    setIsOpenCity(false);
  };

  // 市区町村 都道府県
  const CitySelectPrefItems = municipality.map((pref: Municipality) => (
    <option
      key={`${pref.region_code},${pref.prefecture_code}`}
      value={`${pref.region_code},${pref.prefecture_code}`}
    >
      {pref.prefecture_name}
    </option>
  ));

  const onChangeCitySelectPref = (e: any) => {
    setCitySelectPref(e.target.value);
  };

  const onChangeCheckedCity = (e: any) => {
    const selected: MunicipalityCode[] = [];
    const checked: MunicipalityCode[] = [];
    // 更新しない選択済み現在地
    currentLocation.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
        e.target.value
      ) {
        selected.push(item);
      }
    });
    // 更新しないチェックボックス
    checkedCity.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
        e.target.value
      ) {
        checked.push(item);
      }
    });
    const value: string[] = e.target.value.split(',');
    if (e.target.checked) {
      // 更新する選択済み現在地
      selected.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
      // 更新するチェックボックス
      checked.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
    }
    // 選択済み現在地の更新
    setCurrentLocation(selected);
    // チェックボックスの更新
    setCheckedCity(checked);
  };

  const CityCheckboxItems = municipality
    .find(
      (pref: Municipality) =>
        citySelectPref === `${pref.region_code},${pref.prefecture_code}`
    )
    ?.cities.map((city: City, i: Number) => {
      const prefCode: string[] = citySelectPref.split(',');
      return (
        <label key={`${city.city_code}`}>
          <input
            type="checkbox"
            id={`current_location_city_${i}`}
            value={`${prefCode[0]},${prefCode[1]},${city.city_code},0`}
            checked={checkedCity.some(
              (checked) =>
                checked.region_code === Number(prefCode[0]) &&
                checked.prefecture_code === Number(prefCode[1]) &&
                checked.city_code === city.city_code
              // checked.village_code === 0
            )}
            onChange={onChangeCheckedCity}
          />
          <label
            htmlFor={`current_location_city_${i}`}
            className="checkbox location"
          >
            {city.city_name}
          </label>
        </label>
      );
    });
  // #endregion 市区町村

  // #region  村域以下
  const [isOpenVillage, setIsOpenVillage] = useState<boolean>(false);
  const [villageSelectPref, setVillageSelectPref] = useState<string>('');
  const [villageSelectCity, setVillageSelectCity] = useState<string>('');
  const [checkedVillage, setCheckedVillage] = useState<MunicipalityCode[]>([]);

  useEffect(() => {
    if (
      selectedHistory?.currentLocation &&
      Array.isArray(selectedHistory.currentLocation)
    ) {
      const updatedLocations: MunicipalityCode[] =
        selectedHistory.currentLocation.map(
          (location: {
            regionCode: any;
            prefectureCode: any;
            cityCode: any;
            villageCode: any;
          }) => ({
            region_code: location.regionCode ?? 0,
            prefecture_code: location.prefectureCode ?? 0,
            city_code: location.cityCode ?? 0,
            village_code: location.villageCode ?? 0,
          })
        );

      setCurrentLocation(updatedLocations);

      // 既存の都道府県データを取得
      const firstPrefecture = updatedLocations.find(
        (location) => location.city_code === 0 && location.village_code === 0
      );

      if (firstPrefecture) {
        const initialPrefValue = `${firstPrefecture.region_code},${firstPrefecture.prefecture_code}`;
        setVillageSelectPref(initialPrefValue);
      }

      // 既存の市区町村データを取得
      const firstCity = updatedLocations.find(
        (location) => location.city_code !== 0 && location.village_code === 0
      );

      if (firstCity) {
        const initialCityValue = `${firstCity.region_code},${firstCity.prefecture_code},${firstCity.city_code}`;
        setVillageSelectCity(initialCityValue);
      }

      // 既存の村域データをチェックボックスに反映
      const onlyVillages = updatedLocations.filter(
        (location) => location.village_code !== 0
      );
      setCheckedVillage(onlyVillages);
    }
  }, [selectedHistory]);

  const openVillage = () => {
    setIsOpenVillage(true);
  };

  const closeVillage = () => {
    setIsOpenVillage(false);
  };

  // 村域以下 都道府県
  const VillageSelectPrefItems = municipality.map((pref: Municipality) => (
    <option
      key={`${pref.region_code},${pref.prefecture_code}`}
      value={`${pref.region_code},${pref.prefecture_code}`}
    >
      {pref.prefecture_name}
    </option>
  ));

  const onChangeVillageSelectPref = (e: any) => {
    setVillageSelectPref(e.target.value);
  };

  // 村域以下 市区町村
  const VillageSelectCityItems = municipality
    .find(
      (pref: Municipality) =>
        villageSelectPref === `${pref.region_code},${pref.prefecture_code}`
    )
    ?.cities.map((city: City) => {
      if (city.villages) {
        return (
          <option
            key={`${villageSelectPref},${city.city_code}`}
            value={`${villageSelectPref},${city.city_code}`}
          >
            {city.city_name}
          </option>
        );
      }
      return null;
    });

  const onChangeVillageSelectCity = (e: any) => {
    setVillageSelectCity(e.target.value);
  };

  const onChangeCheckedVillage = (e: any) => {
    const selected: MunicipalityCode[] = [];
    const checked: MunicipalityCode[] = [];
    // 更新しない選択済み現在地
    currentLocation.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
        e.target.value
      ) {
        selected.push(item);
      }
    });
    // 更新しないチェックボックス
    checkedVillage.forEach((item: MunicipalityCode) => {
      if (
        `${item.region_code},${item.prefecture_code},${item.city_code},${item.village_code}` !==
        e.target.value
      ) {
        checked.push(item);
      }
    });
    const value: string[] = e.target.value.split(',');
    if (e.target.checked) {
      // 更新する選択済み現在地
      selected.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
      // 更新するチェックボックス
      checked.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
        city_code: Number(value[2]),
        village_code: Number(value[3]),
      });
    }
    // 選択済み現在地の更新
    setCurrentLocation(selected);
    // チェックボックスの更新
    setCheckedVillage(checked);
  };

  const VillageCheckboxItems = municipality
    .find(
      (pref: Municipality) =>
        villageSelectPref === `${pref.region_code},${pref.prefecture_code}`
    )
    ?.cities.find(
      (city: City) =>
        villageSelectCity === `${villageSelectPref},${city.city_code}`
    )
    ?.villages?.map((village: Village, i: Number) => {
      const cityCode: string[] = villageSelectCity.split(',');
      return (
        <label key={`village_checkbox_${village.village_name}`}>
          <input
            type="checkbox"
            id={`village_${i}`}
            value={`${villageSelectCity},${village.village_code}`}
            checked={checkedVillage.some(
              (checked) =>
                checked.region_code === Number(cityCode[0]) &&
                checked.prefecture_code === Number(cityCode[1]) &&
                checked.city_code === Number(cityCode[2]) &&
                checked.village_code === village.village_code
            )}
            onChange={onChangeCheckedVillage}
          />
          <label htmlFor={`village_${i}`} className="checkbox location">
            {village.village_name}
          </label>
        </label>
      );
    });
  // #endregion 村域以下

  // #region return
  return (
    <>
      <dt>{CURRENT_LOCATION_TEXT}</dt>
      <dd>
        <dl className="box location current_location_selected">
          {currentLocation.length > 0
            ? selectedItems
            : LOCATION_NOT_SELECTED_TEXT}
        </dl>
        {isOpenPref ? (
          <dl className="box location">
            <input
              type="button"
              className="location up"
              value={LOCATION_SELECT_PREFECTURE_TEXT}
              onClick={closePref}
            />
            <dd className="location">
              <label>
                <input
                  type="radio"
                  id="all_current_location"
                  checked={isAllPref}
                  onClick={onClickAllPref}
                  onChange={() => {}}
                />
                <label
                  htmlFor="all_current_location"
                  className={
                    isAllPref ? 'radio location' : 'radio location off'
                  }
                >
                  {LOCATION_ALL_TEXT}
                </label>
                <br />
              </label>
              {PrefCheckboxItems}
              <label key="-1,0">
                <input
                  type="checkbox"
                  id="current_location_pref_-1"
                  value="-1,0,0,0"
                  checked={checkedPref.some(
                    (checked) =>
                      checked.region_code === -1 &&
                      checked.prefecture_code === 0 &&
                      checked.city_code === 0 &&
                      checked.village_code === 0
                  )}
                  onChange={onChangeCheckedPref}
                />
                <label
                  htmlFor="current_location_pref_-1"
                  className={
                    !isAllPref ? 'checkbox location' : 'checkbox location off'
                  }
                >
                  {LOCATION_UNDEFINED_TEXT}
                </label>
              </label>
            </dd>
          </dl>
        ) : (
          <dl className="box location">
            <input
              type="button"
              className="location down"
              value={LOCATION_SELECT_PREFECTURE_TEXT}
              onClick={openPref}
            />
          </dl>
        )}
        {isOpenCity ? (
          <dl className="box location">
            <input
              type="button"
              className="location up"
              value={LOCATION_SELECT_CITY_TEXT}
              onClick={closeCity}
            />
            <dd className="location">
              <select
                name="city_select_pref"
                value={citySelectPref ?? ''}
                onChange={onChangeCitySelectPref}
              >
                <option hidden>{LOCATION_SELECT_DEFAULT_TEXT}</option>
                {CitySelectPrefItems}
              </select>
            </dd>
            <dd className="location">{CityCheckboxItems}</dd>
          </dl>
        ) : (
          <dl className="box location">
            <input
              type="button"
              className="location down"
              value={LOCATION_SELECT_CITY_TEXT}
              onClick={openCity}
            />
          </dl>
        )}
        {isOpenVillage ? (
          <dl className="box location">
            <input
              type="button"
              className="location up"
              value={LOCATION_SELECT_VILLAGE_TEXT}
              onClick={closeVillage}
            />

            <dd className="location">
              <select
                name="village_select_pref"
                value={villageSelectPref}
                onChange={onChangeVillageSelectPref}
              >
                <option hidden>{LOCATION_SELECT_DEFAULT_TEXT}</option>
                {VillageSelectPrefItems}
              </select>
              <select
                name="village_select_city"
                value={`${villageSelectCity}`}
                onChange={onChangeVillageSelectCity}
              >
                <option hidden>{LOCATION_SELECT_DEFAULT_TEXT}</option>
                {VillageSelectCityItems}
              </select>
            </dd>
            <dd className="location">{VillageCheckboxItems}</dd>
          </dl>
        ) : (
          <dl className="box location">
            <input
              type="button"
              className="location down"
              value={LOCATION_SELECT_VILLAGE_TEXT}
              onClick={openVillage}
            />
          </dl>
        )}
      </dd>
    </>
  );
  // #endregion return
};

/**
 * 居住地コンポーネント
 * @param municipality
 * @param livingLocation
 * @param setLivingLocation
 * @param selectedHistory
 */
export const ReservationLivingLocation = (props: any) => {
  const { municipality, livingLocation, setLivingLocation, selectedHistory } =
    props;

  useEffect(() => {
    if (selectedHistory?.livingLocation) {
      const updatedLocations = selectedHistory.livingLocation.map(
        (location: { regionCode: any; prefectureCode: any }) => ({
          region_code: location.regionCode,
          prefecture_code: location.prefectureCode,
        })
      );

      setLivingLocation(updatedLocations);
    }
  }, [selectedHistory]);

  // #region 選択済み居住地
  const selectedItems = livingLocation.map(
    (location: PrefectureCode, i: Number) => {
      // regionが0だったら「すべて(未設定含む)」
      if (location.region_code === 0) {
        return (
          <span key="0,0">
            {i.valueOf() > 0 ? '/' : null}
            {LOCATION_ALL_SELECTED_TEXT}
          </span>
        );
      }
      // regionが99だったら「海外」
      if (location.region_code === 99) {
        return (
          <span key="99,0">
            {i.valueOf() > 0 ? '/' : null}
            {LOCATION_OVERSEAS_TEXT}
          </span>
        );
      }
      // regionが-1だったら「未設定」
      if (location.region_code === -1) {
        return (
          <span key="-1,0">
            {i.valueOf() > 0 ? '/' : null}
            {LOCATION_UNDEFINED_TEXT}
          </span>
        );
      }
      // 都道府県
      const selectedPref = municipality.find(
        (item: Municipality) =>
          item.region_code === location.region_code &&
          item.prefecture_code === location.prefecture_code
      );
      const name = selectedPref ? selectedPref.prefecture_name : '';
      if (name === '') {
        return null;
      }
      return (
        <span key={`${location.region_code},${location.prefecture_code}`}>
          {i.valueOf() > 0 ? '/' : null}
          {name}
        </span>
      );
    }
  );
  // #endregion 選択済み現在地

  // #region 都道府県
  const [isOpenPref, setIsOpenPref] = useState<boolean>(false);
  const [isAllPref, setIsAllPref] = useState<boolean>(true);
  const [checkedPref, setCheckedPref] = useState<PrefectureCode[]>([]);

  useEffect(() => {
    if (selectedHistory?.livingLocation) {
      console.log(
        'Setting livingLocation and checkedPref from selectedHistory:',
        selectedHistory.livingLocation
      );

      const updatedLocations = selectedHistory.livingLocation.map(
        (location: { regionCode: any; prefectureCode: any }) => ({
          region_code: location.regionCode,
          prefecture_code: location.prefectureCode,
        })
      );

      setLivingLocation(updatedLocations);
      setCheckedPref(updatedLocations);

      // すべての都道府県が選択されている場合は `setIsAllPref(true)`
      if (
        updatedLocations.some(
          (location: { region_code: number; prefecture_code: number }) =>
            location.region_code === 0 && location.prefecture_code === 0
        )
      ) {
        setIsAllPref(true);
      } else {
        setIsAllPref(false);
      }
    }
  }, [selectedHistory]);

  const openPref = () => {
    setIsOpenPref(true);
  };

  const closePref = () => {
    setIsOpenPref(false);
  };

  const onClickAllPref = () => {
    const before = isAllPref;
    const selected: PrefectureCode[] = [];
    // すべてを選択した時
    if (!before) {
      selected.push({
        region_code: 0,
        prefecture_code: 0,
      });
    }
    // すべてを解除した時
    else {
      checkedPref.forEach((item: PrefectureCode) => {
        selected.push(item);
      });
    }
    setIsAllPref(!before);
    setLivingLocation(selected);
  };

  const onChangeCheckedPref = (e: any) => {
    const selected: PrefectureCode[] = [];
    const checked: PrefectureCode[] = [];
    // 更新しない選択済み居住地
    livingLocation.forEach((item: PrefectureCode) => {
      if (
        `${item.region_code},${item.prefecture_code}` !== e.target.value &&
        item.region_code !== 0
      ) {
        selected.push(item);
      }
    });
    // 更新しないチェックボックス
    checkedPref.forEach((item: PrefectureCode) => {
      if (`${item.region_code},${item.prefecture_code}` !== e.target.value) {
        checked.push(item);
        // すべてから変更する場合
        if (isAllPref) {
          selected.push(item);
        }
      }
    });
    const value: string[] = e.target.value.split(',');
    if (e.target.checked) {
      // 更新する選択済み居住地
      selected.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
      });
      // 更新するチェックボックス
      checked.push({
        region_code: Number(value[0]),
        prefecture_code: Number(value[1]),
      });
    }
    // すべての選択を解除
    setIsAllPref(false);
    // 選択済み現在地の更新
    setLivingLocation(selected);
    // チェックボックスの更新
    setCheckedPref(checked);
  };

  const PrefCheckboxItems = municipality.map(
    (pref: Municipality, i: Number) => (
      <label key={`${pref.region_code},${pref.prefecture_code}`}>
        <input
          type="checkbox"
          id={`living_location_pref_${i}`}
          value={`${pref.region_code},${pref.prefecture_code}`}
          checked={checkedPref.some(
            (checked) =>
              checked.region_code === pref.region_code &&
              checked.prefecture_code === pref.prefecture_code
          )}
          onChange={onChangeCheckedPref}
        />
        <label
          htmlFor={`living_location_pref_${i}`}
          className={!isAllPref ? 'checkbox location' : 'checkbox location off'}
        >
          {pref.prefecture_name}
        </label>
      </label>
    )
  );
  // #endregion 都道府県

  // #region return
  return (
    <>
      <dt>{LIVING_LOCATION_TEXT}</dt>
      <dd>
        <dl className="box location living_location_selected">
          {livingLocation.length > 0
            ? selectedItems
            : LOCATION_NOT_SELECTED_TEXT}
        </dl>
        {isOpenPref ? (
          <dl className="box location">
            <input
              type="button"
              className="location up"
              value={LOCATION_SELECT_PREFECTURE_TEXT}
              onClick={closePref}
            />
            <dd className="location">
              <label>
                <input
                  type="radio"
                  id="all_living_location_"
                  checked={isAllPref}
                  onClick={onClickAllPref}
                  onChange={() => {}}
                />
                <label
                  htmlFor="all_living_location_"
                  className={
                    isAllPref ? 'radio location' : 'radio location off'
                  }
                >
                  {LOCATION_ALL_TEXT}
                </label>
              </label>
              <br />
              {PrefCheckboxItems}
              <label key="99,0">
                <input
                  type="checkbox"
                  id="living_location_pref_99"
                  value="99,0"
                  checked={checkedPref.some(
                    (checked) =>
                      checked.region_code === 99 &&
                      checked.prefecture_code === 0
                  )}
                  onChange={onChangeCheckedPref}
                />
                <label
                  htmlFor="living_location_pref_99"
                  className={
                    !isAllPref ? 'checkbox location' : 'checkbox location off'
                  }
                >
                  {LOCATION_OVERSEAS_TEXT}
                </label>
              </label>
              <label key="-1,0">
                <input
                  type="checkbox"
                  id="living_location_pref_-1"
                  value="-1,0"
                  checked={checkedPref.some(
                    (checked) =>
                      checked.region_code === -1 &&
                      checked.prefecture_code === 0
                  )}
                  onChange={onChangeCheckedPref}
                />
                <label
                  htmlFor="living_location_pref_-1"
                  className={
                    !isAllPref ? 'checkbox location' : 'checkbox location off'
                  }
                >
                  {LOCATION_UNDEFINED_TEXT}
                </label>
              </label>
            </dd>
          </dl>
        ) : (
          <dl className="box location">
            <input
              type="button"
              className="location down"
              value={LOCATION_SELECT_PREFECTURE_TEXT}
              onClick={openPref}
            />
          </dl>
        )}
      </dd>
    </>
  );
  // #endregion return
};

/**
 * 性別コンポーネント
 * @param setGender
 * @param selectedHistory
 */
export const Gender = (props: any) => {
  const { setGender, selectedHistory } = props;
  const [isAll, setIsAll] = useState<boolean>(true);
  const [checked, setChecked] = useState<Number[]>([]);

  useEffect(() => {
    if (selectedHistory?.gender) {
      if (
        [
          GENDER_MALE_CODE,
          GENDER_FEMALE_CODE,
          GENDER_OTHER_CODE,
          GENDER_UNDEFINED_CODE,
        ].every((g) => selectedHistory.gender.includes(g))
      ) {
        setIsAll(true);
        setChecked([
          GENDER_MALE_CODE,
          GENDER_FEMALE_CODE,
          GENDER_OTHER_CODE,
          GENDER_UNDEFINED_CODE,
        ]);
      } else {
        setIsAll(false);
        setChecked(selectedHistory.gender);
      }
      setGender(selectedHistory.gender);
    }
  }, [selectedHistory]);

  const onClickAll = () => {
    // すべてを選択した時
    if (!isAll) {
      setIsAll(true);
      setGender([
        GENDER_MALE_CODE,
        GENDER_FEMALE_CODE,
        GENDER_OTHER_CODE,
        GENDER_UNDEFINED_CODE,
      ]);
    }
    // すべてを解除した時
    else {
      const list: Number[] = [];
      checked.forEach((item: Number) => {
        list.push(item);
      });
      setIsAll(false);
      setGender(list);
    }
  };

  const onChangeChecked = (e: any) => {
    const list: Number[] = [];
    checked.forEach((item: Number) => {
      if (item !== Number(e.target.value)) {
        list.push(item);
      }
    });
    if (e.target.checked) {
      list.push(Number(e.target.value));
    }
    list.sort();
    setIsAll(false);
    setChecked(list);
    setGender(list);
  };

  return (
    <>
      <dt>{GENDER_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="gender_all"
            checked={isAll}
            onClick={onClickAll}
            onChange={() => {}}
          />
          <label htmlFor="gender_all" className={isAll ? 'radio' : 'radio off'}>
            {GENDER_ALL_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="gender_male"
            value={GENDER_MALE_CODE}
            checked={checked.includes(GENDER_MALE_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="gender_male"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {GENDER_MALE_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="gender_female"
            value={GENDER_FEMALE_CODE}
            checked={checked.includes(GENDER_FEMALE_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="gender_female"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {GENDER_FEMALE_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="gender_other"
            value={GENDER_OTHER_CODE}
            checked={checked.includes(GENDER_OTHER_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="gender_other"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {GENDER_OTHER_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="gender_undefined"
            value={GENDER_UNDEFINED_CODE}
            checked={checked.includes(GENDER_UNDEFINED_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="gender_undefined"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {GENDER_UNDEFINED_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * 年代コンポーネント
 * @param setAge
 * @param selectedHistory
 */
export const Age = (props: any) => {
  const { setAge, selectedHistory } = props;
  const [isAll, setIsAll] = useState<boolean>(true);
  const [checked, setChecked] = useState<Number[]>([]);

  useEffect(() => {
    if (selectedHistory?.age) {
      if (
        [
          AGE_10S_CODE,
          AGE_20S_CODE,
          AGE_30S_CODE,
          AGE_40S_CODE,
          AGE_50S_CODE,
          AGE_60S_CODE,
          AGE_UNDEFINED_CODE,
        ].every((g) => selectedHistory.age.includes(g))
      ) {
        setIsAll(true);
        setChecked([
          AGE_10S_CODE,
          AGE_20S_CODE,
          AGE_30S_CODE,
          AGE_40S_CODE,
          AGE_50S_CODE,
          AGE_60S_CODE,
          AGE_UNDEFINED_CODE,
        ]);
      } else {
        setIsAll(false);
        setChecked(selectedHistory.age);
      }
      setAge(selectedHistory.age);
    }
  }, [selectedHistory]);

  const onClickAll = () => {
    // すべてを選択した時
    if (!isAll) {
      setIsAll(true);
      setAge([
        AGE_10S_CODE,
        AGE_20S_CODE,
        AGE_30S_CODE,
        AGE_40S_CODE,
        AGE_50S_CODE,
        AGE_60S_CODE,
        AGE_UNDEFINED_CODE,
      ]);
    }
    // すべてを解除した時
    else {
      const list: Number[] = [];
      checked.forEach((item: Number) => {
        list.push(item);
      });
      setIsAll(false);
      setAge(list);
    }
  };

  const onChangeChecked = (e: any) => {
    const list: Number[] = [];
    checked.forEach((item: Number) => {
      if (item !== Number(e.target.value)) {
        list.push(item);
      }
    });
    if (e.target.checked) {
      list.push(Number(e.target.value));
    }
    list.sort();
    setIsAll(false);
    setChecked(list);
    setAge(list);
  };

  return (
    <>
      <dt>{AGE_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="age_all"
            checked={isAll}
            onClick={onClickAll}
            onChange={() => {}}
          />
          <label htmlFor="age_all" className={isAll ? 'radio' : 'radio off'}>
            {AGE_ALL_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_10s"
            value={AGE_10S_CODE}
            checked={checked.includes(AGE_10S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_10s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_10S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_20s"
            value={AGE_20S_CODE}
            checked={checked.includes(AGE_20S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_20s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_20S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_30s"
            value={AGE_30S_CODE}
            checked={checked.includes(AGE_30S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_30s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_30S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_40s"
            value={AGE_40S_CODE}
            checked={checked.includes(AGE_40S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_40s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_40S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_50s"
            value={AGE_50S_CODE}
            checked={checked.includes(AGE_50S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_50s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_50S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_60s"
            value={AGE_60S_CODE}
            checked={checked.includes(AGE_60S_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_60s"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_60S_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="age_undefined"
            value={AGE_UNDEFINED_CODE}
            checked={checked.includes(AGE_UNDEFINED_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="age_undefined"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {AGE_UNDEFINED_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * 言語コンポーネント
 * @param setLanguage
 * @param selectedHistory
 */
export const Language = (props: any) => {
  const { setLanguage, selectedHistory } = props;
  const [isAll, setIsAll] = useState<boolean>(true);
  const [checked, setChecked] = useState<String[]>([]);

  useEffect(() => {
    if (selectedHistory?.language) {
      if (
        [
          LANGUAGE_JA_CODE,
          LANGUAGE_EN_CODE,
          LANGUAGE_KO_CODE,
          LANGUAGE_ZHS_CODE,
          LANGUAGE_ZHT_CODE,
        ].every((g) => selectedHistory.language.includes(g))
      ) {
        setIsAll(true);
        setChecked([
          LANGUAGE_JA_CODE,
          LANGUAGE_EN_CODE,
          LANGUAGE_KO_CODE,
          LANGUAGE_ZHS_CODE,
          LANGUAGE_ZHT_CODE,
        ]);
      } else {
        setIsAll(false);
        setChecked(selectedHistory.language);
      }
      setLanguage(selectedHistory.language);
    }
  }, [selectedHistory]);

  const onClickAll = () => {
    // すべてを選択した時
    if (!isAll) {
      setIsAll(true);
      setLanguage([
        LANGUAGE_JA_CODE,
        LANGUAGE_EN_CODE,
        LANGUAGE_KO_CODE,
        LANGUAGE_ZHS_CODE,
        LANGUAGE_ZHT_CODE,
      ]);
    }
    // すべてを解除した時
    else {
      const list: String[] = [];
      checked.forEach((item: String) => {
        list.push(item);
      });
      setIsAll(false);
      setLanguage(list);
    }
  };

  const onChangeChecked = (e: any) => {
    const list: String[] = [];
    checked.forEach((item: String) => {
      if (item !== String(e.target.value)) {
        list.push(item);
      }
    });
    if (e.target.checked) {
      list.push(String(e.target.value));
    }
    list.sort();
    setIsAll(false);
    setChecked(list);
    setLanguage(list);
  };

  return (
    <>
      <dt>{LANGUAGE_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="language_all"
            checked={isAll}
            onClick={onClickAll}
            onChange={() => {}}
          />
          <label
            htmlFor="language_all"
            className={isAll ? 'radio' : 'radio off'}
          >
            {LANGUAGE_ALL_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="language_ja"
            value={LANGUAGE_JA_CODE}
            checked={checked.includes(LANGUAGE_JA_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="language_ja"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {LANGUAGE_JA_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="language_en"
            value={LANGUAGE_EN_CODE}
            checked={checked.includes(LANGUAGE_EN_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="language_en"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {LANGUAGE_EN_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="language_ko"
            value={LANGUAGE_KO_CODE}
            checked={checked.includes(LANGUAGE_KO_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="language_ko"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {LANGUAGE_KO_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="language_zhs"
            value={LANGUAGE_ZHS_CODE}
            checked={checked.includes(LANGUAGE_ZHS_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="language_zhs"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {LANGUAGE_ZHS_TEXT}
          </label>
        </label>
        <label>
          <input
            type="checkbox"
            id="language_zht"
            value={LANGUAGE_ZHT_CODE}
            checked={checked.includes(LANGUAGE_ZHT_CODE)}
            onChange={onChangeChecked}
          />
          <label
            htmlFor="language_zht"
            className={!isAll ? 'checkbox' : 'checkbox off'}
          >
            {LANGUAGE_ZHT_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * テスト通知コンポーネント
 * @param isDebug
 * @param setIsDebug
 * @param selectedHistory
 */
export const IsDebug = (props: any) => {
  const { isDebug, setIsDebug, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory.isDebug === IS_DEBUG_ON_CODE) {
      setIsDebug(IS_DEBUG_ON_CODE);
    } else if (selectedHistory.isDebug === IS_DEBUG_OFF_CODE) {
      setIsDebug(IS_DEBUG_OFF_CODE);
    } else {
      setIsDebug(IS_DEBUG_ON_CODE);
    }
  }, [selectedHistory]);

  const onChange = (e: any) => {
    setIsDebug(Number(e.target.value));
  };

  return (
    <>
      <dt>{IS_DEBUG_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="is_debug_on"
            value={IS_DEBUG_ON_CODE}
            checked={isDebug === IS_DEBUG_ON_CODE}
            onChange={onChange}
          />
          <label htmlFor="is_debug_on" className="radio">
            {IS_DEBUG_ON_TEXT}
          </label>
        </label>
        <label>
          <input
            type="radio"
            id="is_debug_off"
            value={IS_DEBUG_OFF_CODE}
            checked={isDebug === IS_DEBUG_OFF_CODE}
            onChange={onChange}
          />
          <label htmlFor="is_debug_off" className="radio">
            {IS_DEBUG_OFF_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * 通知方法コンポーネント
 * @param isReservation
 * @param setIsReservation
 * @param selectedHistory
 */
export const IsReservation = (props: any) => {
  const { isReservation, setIsReservation, selectedHistory } = props;

  useEffect(() => {
    if (selectedHistory.isReservation === IS_RESERVATION_IMMEDIATE_CODE) {
      setIsReservation(IS_RESERVATION_IMMEDIATE_CODE);
    } else {
      setIsReservation(IS_RESERVATION_RESERVATION_CODE);
    }
  }, [selectedHistory]);

  const onChange = (e: any) => {
    setIsReservation(Number(e.target.value));
  };

  return (
    <>
      <dt>{IS_RESERVATION_TEXT}</dt>
      <dd>
        <label>
          <input
            type="radio"
            id="push_timing_immediate"
            value={IS_RESERVATION_IMMEDIATE_CODE}
            checked={isReservation === IS_RESERVATION_IMMEDIATE_CODE}
            onChange={onChange}
          />
          <label htmlFor="push_timing_immediate" className="radio">
            {IS_RESERVATION_IMMEDIATE_TEXT}
          </label>
        </label>
        <label>
          <input
            type="radio"
            id="push_timing_reservation"
            value={IS_RESERVATION_RESERVATION_CODE}
            checked={isReservation === IS_RESERVATION_RESERVATION_CODE}
            onChange={onChange}
          />
          <label htmlFor="push_timing_reservation" className="radio">
            {IS_RESERVATION_RESERVATION_TEXT}
          </label>
        </label>
      </dd>
    </>
  );
};

/**
 * 予約コンポーネント
 * @param reservedAt
 * @param setReservedAt
 */
export const Reservation = (props: any) => {
  const { reservedAt, setReservedAt, selectedHistory } = props;

  // selectedHistory.reservedをYYYY-MM-DDTHH:MM形式に変換
  const formatToDatetimeLocal = (strReservedAt: string): string => {
    if (!strReservedAt) return '';
    const date = new Date(strReservedAt);
    date.setHours(date.getHours() + 9);
    return date.toISOString().slice(0, 16);
  };

  useEffect(() => {
    if (selectedHistory?.reservedAt) {
      setReservedAt(formatToDatetimeLocal(selectedHistory.reservedAt));
    }
  }, [selectedHistory]);

  // `Date` を `YYYY-MM-DD` 形式に変換
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // 現在の日付を `YYYY-MM-DD` 形式で取得
  const getMinDate = () => {
    const now = new Date();
    return formatDate(now);
  };

  // 1年後の日付を取得
  const getMaxDate = () => {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    return formatDate(nextYear);
  };

  // `Date` を `HH` `MM` に分割（10分刻み）
  const formatTimeParts = (date: Date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = Math.floor(date.getMinutes() / 10) * 10;
    return {
      hour: hours,
      minute: String(minutes).padStart(2, '0'),
    };
  };

  // 予約日時の分割
  const defaultDate = reservedAt ? reservedAt.split('T')[0] : getMinDate();
  const defaultTimeParts = reservedAt
    ? {
        hour: reservedAt.split('T')[1].split(':')[0],
        minute: reservedAt.split('T')[1].split(':')[1],
      }
    : formatTimeParts(new Date());

  // 予約日時の変更処理
  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReservedAt(
      `${e.target.value}T${defaultTimeParts.hour}:${defaultTimeParts.minute}`
    );
  };

  const onChangeHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReservedAt(
      `${defaultDate}T${e.target.value}:${defaultTimeParts.minute}`
    );
  };

  const onChangeMinute = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReservedAt(`${defaultDate}T${defaultTimeParts.hour}:${e.target.value}`);
  };

  return (
    <>
      <dt> </dt>
      <dd>
        <label className="reservation_date">
          {RESERVED_AT_TEXT}
          <input
            type="date"
            value={defaultDate}
            min={getMinDate()} // 現在の日付以降のみ選択可
            max={getMaxDate()} // 1年以内のみ選択可
            onChange={onChangeDate}
          />
          <select value={defaultTimeParts.hour} onChange={onChangeHour}>
            {Array.from(Array(24).keys()).map((h) => {
              const hour = String(h).padStart(2, '0');
              return (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              );
            })}
          </select>
          :
          <select value={defaultTimeParts.minute} onChange={onChangeMinute}>
            {[0, 10, 20, 30, 40, 50].map((m) => {
              const minute = String(m).padStart(2, '0');
              return (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              );
            })}
          </select>
        </label>
      </dd>
    </>
  );
};
