/* eslint-disable camelcase */
// TODO: 環境の接続先をここで管理しているため注意
export const RUNNING_TYPE: string = 'master'; // master,develop,local,tester
export const RUNNING_TYPE_MASTER: string = 'master';
export const RUNNING_TYPE_DEVELOP: string = 'develop';
export const RUNNING_TYPE_LOCAL: string = 'local';
export const RUNNING_TYPE_TESTER: string = 'tester';

export const TESTER_LOGIN_ID: string = 'tester@rc.com';
export const TESTER_LOGIN_PASSWORD: string = 'tester';

// 本番環境
export const LOGIN_API_URL = 'https://bosai-cloud-4-sb.com/api/v1/login';
export const PUSH_SEND_API_URL =
  'https://bosai-cloud-4-sb.com/api/v1/push/send';
export const PUSH_RESERVED_API_URL =
  'https://bosai-cloud-4-sb.com/api/v1/push/reserved';
export const PUSH_LOG_API_URL = 'https://bosai-cloud-4-sb.com/api/v1/push/log';
export const PUSH_DELETE_API_URL =
  'https://bosai-cloud-4-sb.com/api/v1/push/delete';
export const GET_MUNICIPALITY_URL =
  'https://s3.ap-northeast-1.amazonaws.com/prod.rcsc.co.jp/sb_push/municipalities_exmaple.json';
// 検証環境
export const LOGIN_API_DEV_URL = 'http://133.242.238.56/api/v1/login';
export const PUSH_SEND_API_DEV_URL = 'http://133.242.238.56/api/v1/push/send';
export const PUSH_RESERVED_API_DEV_URL =
  'http://133.242.238.56/api/v1/push/reserved';
export const PUSH_LOG_API_DEV_URL = 'http://133.242.238.56/api/v1/push/log';
export const PUSH_DELETE_API_DEV_URL =
  'http://133.242.238.56/api/v1/push/delete';
// ローカル
export const LOGIN_API_LOCAL_URL = 'http://localhost:8080/api/v1/login';
export const PUSH_SEND_API_LOCAL_URL = 'http://localhost:8080/api/v1/push/send';
export const PUSH_RESERVED_API_LOCAL_URL =
  'http://localhost:8080/api/v1/push/reserved';
export const PUSH_LOG_API_LOCAL_URL = 'http://localhost:8080/api/v1/push/log';
export const PUSH_DELETE_API_LOCAL_URL =
  'http://localhost:8080/api/v1/push/delete';
// s3
export const PUSH_LOG_API_S3_URL =
  'https://s3.ap-northeast-1.amazonaws.com/prod.rcsc.co.jp/sb_push/notification_history.json';
// APIキー
export const API_KEY =
  'B307236856DA753BA6009F0699EA93981A3088DABF00ECBD350EC38F9970376F';
export const API_KEY_RC_TEST =
  '25D6C9B6D15216BDB3A3AEE5A669A30739CF4A4F0FA7D1F6098BFDA486751280';
// error_code
export const ERROR_CODE_15 = 'invalid_login_id';
export const ERROR_CODE_16 = 'invalid_login_password';
// login時エラーメッセージ
export const ERROR_MESSAGE_WRONG_LOGIN = 'IDまたはパスワードが誤っています。';
export const ERROR_MESSAGE_ID_LOGIN = 'IDが誤っています。';
export const ERROR_MESSAGE_PASSWORD_LOGIN = 'パスワードが誤っています。';
export const ERROR_MESSAGE_LOGIN = 'ログインに失敗しました。';
// APIエラー時のメッセージ
export const ERROR_MESSAGE_API =
  'エラーが発生しました。時間をおいてもう一度お試しください';

// #region
export const MENU_PUSH_SEND_TEXT = '通知';
export const MENU_PUSH_LOG_TEXT = '履歴';
export const MENU_PUSH_RESERVATION_TEXT = '予約送信';

export const TITLE_TEXT = 'タイトル';
export const MESSAGE_TEXT = 'メッセージ';
export const ADDITIONAL_TEXT = '付加情報';
export const ADDITIONAL_KEY_TEXT = 'Key';
export const ADDITIONAL_VALUE_TEXT = 'Value';
export const PARAMETER_TEXT = '属性情報';

export const DEVICE_TYPE_TEXT = 'デバイスタイプ';
export const DEVICE_TYPE_ALL_TEXT = 'すべて';
export const DEVICE_TYPE_ANDROID_TEXT = 'Android';
export const DEVICE_TYPE_IOS_TEXT = 'iOS';
export const DEVICE_TYPE_ANDROID_CODE = 'android';
export const DEVICE_TYPE_IOS_CODE = 'ios';

export const LOCATION_SELECT_PREFECTURE_TEXT = '都道府県で絞り込み';
export const LOCATION_SELECT_CITY_TEXT = '市区町村で絞り込み';
export const LOCATION_SELECT_VILLAGE_TEXT = '村域以下で絞り込み';
export const LOCATION_ALL_SELECTED_TEXT = 'すべて(未設定含む)';
export const LOCATION_NOT_SELECTED_TEXT = 'なし';
export const LOCATION_SELECT_DEFAULT_TEXT = '選択してください';
export const LOCATION_ALL_TEXT = 'すべて';
export const LOCATION_OVERSEAS_TEXT = '海外';
export const LOCATION_UNDEFINED_TEXT = '未設定';

export const CURRENT_LOCATION_TEXT = '現在地';
export const LIVING_LOCATION_TEXT = '居住地';

export const GENDER_TEXT = '性別';
export const GENDER_ALL_TEXT = 'すべて';
export const GENDER_MALE_TEXT = '男性';
export const GENDER_FEMALE_TEXT = '女性';
export const GENDER_OTHER_TEXT = 'その他';
export const GENDER_UNDEFINED_TEXT = '無回答';
export const GENDER_MALE_CODE = 1;
export const GENDER_FEMALE_CODE = 2;
export const GENDER_OTHER_CODE = 3;
export const GENDER_UNDEFINED_CODE = 99;

export const AGE_TEXT = '年代';
export const AGE_ALL_TEXT = 'すべて';
export const AGE_10S_TEXT = '10代以下';
export const AGE_20S_TEXT = '20代';
export const AGE_30S_TEXT = '30代';
export const AGE_40S_TEXT = '40代';
export const AGE_50S_TEXT = '50代';
export const AGE_60S_TEXT = '60代以上';
export const AGE_UNDEFINED_TEXT = '無回答';
export const AGE_10S_CODE = 1;
export const AGE_20S_CODE = 2;
export const AGE_30S_CODE = 3;
export const AGE_40S_CODE = 4;
export const AGE_50S_CODE = 5;
export const AGE_60S_CODE = 6;
export const AGE_UNDEFINED_CODE = 99;

export const LANGUAGE_TEXT = '言語';
export const LANGUAGE_ALL_TEXT = 'すべて';
export const LANGUAGE_JA_TEXT = '日本語';
export const LANGUAGE_EN_TEXT = '英語';
export const LANGUAGE_KO_TEXT = '韓国語';
export const LANGUAGE_ZHS_TEXT = '中国語(簡体字)';
export const LANGUAGE_ZHT_TEXT = '中国語(繁体字)';
export const LANGUAGE_JA_CODE = 'ja';
export const LANGUAGE_EN_CODE = 'en';
export const LANGUAGE_KO_CODE = 'ko';
export const LANGUAGE_ZHS_CODE = 'zhs';
export const LANGUAGE_ZHT_CODE = 'zht';

export const IS_DEBUG_TEXT = 'テスト通知';
export const IS_DEBUG_ON_TEXT = 'ON';
export const IS_DEBUG_OFF_TEXT = 'OFF';
export const IS_DEBUG_ON_CODE = 1;
export const IS_DEBUG_OFF_CODE = 0;

export const IS_RESERVATION_TEXT = '通知方法';
export const IS_RESERVATION_IMMEDIATE_TEXT = '即時';
export const IS_RESERVATION_RESERVATION_TEXT = '予約';
export const IS_RESERVATION_IMMEDIATE_CODE = 0;
export const IS_RESERVATION_RESERVATION_CODE = 1;

export const PUSH_LOG_INDEX_TEXT = '通知履歴一覧';
export const PUSH_LOG_DETAIL_TEXT = '通知履歴';

export const PUSH_RESERVATION_INDEX_TEXT = '予約送信一覧';
export const PUSH_RESERVATION_EDIT_TEXT = '予約送信編集';

export const SEND_STATUS_TEXT = '送信状況';
export const SEND_DATETIME_TEXT = '送信日時';
export const SEND_SUCCEED_TEXT = '成功件数';
export const SEND_FAILED_TEXT = '失敗件数';
export const BACK_TO_INDEX_TEXT = '一覧に戻る';

export const SENDCOMPLETION = '送信完了';
export const SENDPROCESSING = '送信処理中';

export const RESERVED_AT_TEXT = '送信予約日時';
// #endregion
