/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
// #region import
import React, { useEffect, useState } from 'react';
import GetPushDelete from '../../../api/PushDeleteAPI';
import {
  PostPushReserved,
  ReservedRequestDto,
} from '../../../api/PushReservedAPI';
import GetMunicipality from '../../../api/MunicipalityAPI';
import { PostPushSend, SendRequestDto } from '../../../api/PushSendAPI';
import {
  Municipality,
  MunicipalityCode,
  PrefectureCode,
  removeMunicipalityCodeZero,
  removePrefectureCodeZero,
} from '../../../models/municipality';
import {
  DEVICE_TYPE_ANDROID_CODE,
  DEVICE_TYPE_IOS_CODE,
  GENDER_MALE_CODE,
  GENDER_FEMALE_CODE,
  GENDER_OTHER_CODE,
  AGE_10S_CODE,
  AGE_20S_CODE,
  AGE_30S_CODE,
  AGE_40S_CODE,
  AGE_50S_CODE,
  AGE_60S_CODE,
  AGE_UNDEFINED_CODE,
  LANGUAGE_JA_CODE,
  LANGUAGE_EN_CODE,
  LANGUAGE_KO_CODE,
  LANGUAGE_ZHS_CODE,
  LANGUAGE_ZHT_CODE,
  PUSH_RESERVATION_EDIT_TEXT,
  ERROR_MESSAGE_API,
  GENDER_UNDEFINED_CODE,
  BACK_TO_INDEX_TEXT,
  IS_RESERVATION_RESERVATION_CODE,
} from '../../../util/Const';
import getBytes from '../../../util/StringUtil';
import {
  Title,
  Message,
  Additional,
  DeviceType,
  ReservationCurrentLocation,
  ReservationLivingLocation,
  Gender,
  Age,
  Language,
  IsDebug,
  IsReservation,
  Reservation,
} from './EditFormItem';

// #endregion import

/**
 * 通知予約画面
 * @param history
 */
const NotificationReservationEdit = (props: any) => {
  const { history, selectedHistory, setSelectedHistory } = props;

  // #region useState
  const [municipality, setMunicipality] = useState<Municipality[]>([]);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [device, setDevice] = useState<string[]>([
    DEVICE_TYPE_ANDROID_CODE,
    DEVICE_TYPE_IOS_CODE,
  ]);
  const [currentLocation, setCurrentLocation] = useState<MunicipalityCode[]>([
    { region_code: 0, prefecture_code: 0, city_code: 0, village_code: 0 },
  ]);
  const [livingLocation, setLivingLocation] = useState<PrefectureCode[]>([
    { region_code: 0, prefecture_code: 0 },
  ]);
  const [gender, setGender] = useState<Number[]>([
    GENDER_MALE_CODE,
    GENDER_FEMALE_CODE,
    GENDER_OTHER_CODE,
    GENDER_UNDEFINED_CODE,
  ]);
  const [age, setAge] = useState<Number[]>([
    AGE_10S_CODE,
    AGE_20S_CODE,
    AGE_30S_CODE,
    AGE_40S_CODE,
    AGE_50S_CODE,
    AGE_60S_CODE,
    AGE_UNDEFINED_CODE,
  ]);
  const [language, setLanguage] = useState<string[]>([
    LANGUAGE_JA_CODE,
    LANGUAGE_EN_CODE,
    LANGUAGE_KO_CODE,
    LANGUAGE_ZHS_CODE,
    LANGUAGE_ZHT_CODE,
    DEVICE_TYPE_IOS_CODE,
  ]);
  const [isDebug, setIsDebug] = useState<Number>(1);
  const [isReservation, setIsReservation] = useState<Number>(1);
  const [reservedAt, setReservedAt] = useState<string>('');
  // #endregion useState

  useEffect(() => {
    GetMunicipality()
      .then((response) => {
        setMunicipality(response);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);

  // 保存ボタン
  const onClickSendBtn = async () => {
    try {
      // タイトル
      if (title.length <= 0) {
        alert('タイトルを入力してください。');
        return;
      }
      if (getBytes(title) > 256) {
        alert('タイトルの文字数が多すぎます。');
        return;
      }
      // メッセージ
      if (message.length <= 0) {
        alert('メッセージを入力してください。');
        return;
      }
      if (getBytes(message) > 1024) {
        alert('メッセージの文字数が多すぎます。');
        return;
      }
      // 付加情報
      if (key.length <= 0 && value.length > 0) {
        alert('付加情報(key)を入力してください。');
        return;
      }
      if (getBytes(key) > 256) {
        alert('付加情報(key)の文字数が多すぎます。');
        return;
      }
      if (key.length > 0 && value.length <= 0) {
        alert('付加情報(value)を入力してください。');
        return;
      }
      if (getBytes(value) > 256) {
        alert('付加情報(value)の文字数が多すぎます。');
        return;
      }
      if (currentLocation.length <= 0) {
        alert('現在地を選択してください。');
        return;
      }
      if (livingLocation.length <= 0) {
        alert('居住地を選択してください。');
        return;
      }
      if (gender.length <= 0) {
        alert('性別を選択してください。');
        return;
      }
      if (age.length <= 0) {
        alert('年代を選択してください。');
        return;
      }
      if (language.length <= 0) {
        alert('言語を選択してください。');
        return;
      }

      if (isReservation === 0) {
        const request: SendRequestDto = {
          title,
          message,
          device,
          current_location: removeMunicipalityCodeZero(currentLocation),
          living_location: removePrefectureCodeZero(livingLocation),
          gender,
          age,
          language,
          additional: key && value ? [{ key, value }] : [],
          is_debug: isDebug,
        };
        const result = confirm('選択された対象へ通知を送信します。');
        if (result) {
          try {
            await PostPushSend(request);
            try {
              await GetPushDelete(selectedHistory.id);
              setSelectedHistory(null);
            } catch (error) {
              console.error('削除エラー:', error);
            }
            history.push('/PushLog');
          } catch (error) {
            console.error('送信エラー:', error);
            alert(ERROR_MESSAGE_API);
          }
        }
      } else if (isReservation === 1) {
        if (!reservedAt || reservedAt.length < 16) {
          alert('予約日時を指定してください。');
          return;
        }
        if (new Date(reservedAt) < new Date()) {
          alert('過去の日時は指定できません。');
          return;
        }
        const formatToDateTime = (reservedAtStr: string): string => {
          if (!reservedAtStr) return '';
          const date = new Date(reservedAtStr);
          date.setHours(date.getHours());
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = '00'; // 秒は固定で 00
          return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        };
        const request: ReservedRequestDto = {
          title,
          message,
          device,
          current_location: removeMunicipalityCodeZero(currentLocation),
          living_location: removePrefectureCodeZero(livingLocation),
          gender,
          age,
          language,
          additional: key && value ? [{ key, value }] : [],
          is_debug: isDebug,
          reserved_at: formatToDateTime(reservedAt),
        };
        const result = confirm('選択された対象への通知内容を保存します。');
        if (result) {
          try {
            await PostPushReserved(request);
            try {
              await GetPushDelete(selectedHistory.id);
              setSelectedHistory(null);
            } catch (error) {
              console.error('削除エラー:', error);
            }
            history.push('/PushReservation');
          } catch (error) {
            console.error('送信エラー:', error);
            alert(ERROR_MESSAGE_API);
          }
        }
      }
    } catch (error) {
      alert(ERROR_MESSAGE_API);
    }
  };

  const handleClickCloseBtn = () => {
    setSelectedHistory(null);
  };

  // 削除ボタン
  const onClickDeleteBtn = async (id: string) => {
    try {
      const result = confirm('選択された対象を削除します。');
      if (result) {
        try {
          await GetPushDelete(id);
          setSelectedHistory(null);
        } catch (error) {
          console.error('削除エラー:', error);
          alert(ERROR_MESSAGE_API);
        }
      }
    } catch (error) {
      alert(ERROR_MESSAGE_API);
    }
  };

  // #region return
  return (
    <div className="main_contents">
      <section className="notification_sending">
        <div className="edit_top">
          <br />
          <h1>{PUSH_RESERVATION_EDIT_TEXT}</h1>
          <span className="busadd_btnarea">
            <button
              type="button"
              className="btn_back"
              onClick={() => handleClickCloseBtn()}
            >
              {BACK_TO_INDEX_TEXT}
            </button>
          </span>
          <dl>
            <Title
              title={title}
              setTitle={setTitle}
              selectedHistory={selectedHistory}
            />
            <Message
              message={message}
              setMessage={setMessage}
              selectedHistory={selectedHistory}
            />
            <Additional
              addKey={key}
              addValue={value}
              setKey={setKey}
              setValue={setValue}
              selectedHistory={selectedHistory}
            />
          </dl>
          <dl className="box zokusei">
            <DeviceType
              device={device}
              setDevice={setDevice}
              selectedHistory={selectedHistory}
            />
            <ReservationCurrentLocation
              municipality={municipality}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              selectedHistory={selectedHistory}
            />
            <ReservationLivingLocation
              municipality={municipality}
              livingLocation={livingLocation}
              setLivingLocation={setLivingLocation}
              selectedHistory={selectedHistory}
            />
            <Gender setGender={setGender} selectedHistory={selectedHistory} />
            <Age setAge={setAge} selectedHistory={selectedHistory} />
            <Language
              setLanguage={setLanguage}
              selectedHistory={selectedHistory}
            />
            <IsDebug
              isDebug={isDebug}
              setIsDebug={setIsDebug}
              selectedHistory={selectedHistory}
            />
            <IsReservation
              isReservation={isReservation}
              setIsReservation={setIsReservation}
              selectedHistory={selectedHistory}
            />
            {isReservation === IS_RESERVATION_RESERVATION_CODE && (
              <Reservation
                reservedAt={reservedAt}
                setReservedAt={setReservedAt}
                selectedHistory={selectedHistory}
              />
            )}
          </dl>
          <div className="btn_area">
            <button type="button" className="btn" onClick={onClickSendBtn}>
              保存
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => onClickDeleteBtn(selectedHistory.id)}
            >
              削除
            </button>
          </div>
        </div>
      </section>
    </div>
  );
  // #endregion return
};
export default NotificationReservationEdit;
