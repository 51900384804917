// #region import
import axios from 'axios';
import {
  RUNNING_TYPE,
  RUNNING_TYPE_MASTER,
  RUNNING_TYPE_DEVELOP,
  RUNNING_TYPE_LOCAL,
  PUSH_RESERVED_API_URL,
  PUSH_RESERVED_API_DEV_URL,
  PUSH_RESERVED_API_LOCAL_URL,
  API_KEY,
  API_KEY_RC_TEST,
} from '../util/Const';
// #endregion import

// #region dto
/**
 * 予約送信APIリクエストIF
 */
export type ReservedRequestDto = {
  title: string;
  message: string;
  device: string[];
  current_location: object[];
  living_location: object[];
  gender: Number[];
  age: Number[];
  language: string[];
  additional: Additional[];
  is_debug: Number;
  reserved_at: string;
};

/**
 * 予約送信APIリクエストIF(付加情報)
 */
export type Additional = {
  key: string;
  value: string;
};
// #endregion dto

/**
 * 予約送信APIリクエスト
 * @param dto
 */
export const PostPushReserved = async (dto: ReservedRequestDto) => {
  let url: string;
  let apiKey: string;
  switch (RUNNING_TYPE) {
    case RUNNING_TYPE_MASTER:
      url = PUSH_RESERVED_API_URL;
      apiKey = API_KEY;
      break;
    case RUNNING_TYPE_DEVELOP:
      url = PUSH_RESERVED_API_DEV_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    case RUNNING_TYPE_LOCAL:
      url = PUSH_RESERVED_API_LOCAL_URL;
      apiKey = API_KEY_RC_TEST;
      break;
    default:
      url = '';
      apiKey = '';
      break;
  }

  if (
    RUNNING_TYPE === RUNNING_TYPE_MASTER ||
    RUNNING_TYPE === RUNNING_TYPE_DEVELOP ||
    RUNNING_TYPE === RUNNING_TYPE_LOCAL
  ) {
    const res = await axios
      .post(url, JSON.stringify(dto), {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
    return res;
  }

  console.info(url);
  console.info(JSON.stringify(dto));
  const res = 'response';
  return res;
};
