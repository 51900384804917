import axios from 'axios';
import { GET_MUNICIPALITY_URL } from '../util/Const';

/**
 * 地点情報取得APIリクエスト
 */
// TODO: APIキー
const GetMunicipality = async () => {
  const url = GET_MUNICIPALITY_URL;
  const res = await axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

export default GetMunicipality;
