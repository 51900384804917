import React, { useContext, useState } from 'react';
import { context } from '../../App';
import LoginHandler from '../../handler/LoginHandler';

/**
 * ログイン画面
 * @param props
 */
const Login = (props: any) => {
  const { dispatch } = useContext(context);
  const { history } = props;
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClickLoginBtn = async () => {
    try {
      await LoginHandler(dispatch, id, password);
      history.push('/PushSend');
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  if (props === null) {
    return null;
  }

  return (
    <div className="main_contents">
      <div id="login_contents">
        <h1>
          <img src="img/logo.png" alt="防災クラウド" />
        </h1>
        <p className="login_alart">{errorMessage}</p>
        <div className="login_area">
          <dl>
            <dt>ID</dt>
            <dd>
              <input
                type="text"
                name="login_mail"
                id="login_mail"
                value={id}
                className="login_text"
                placeholder="企業ID"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setId(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClickLoginBtn();
                  }
                }}
              />
            </dd>
            <dt>パスワード</dt>
            <dd>
              <input
                type="password"
                name="login_pass"
                id="login_pass"
                className="login_text"
                placeholder="Password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClickLoginBtn();
                  }
                }}
              />
            </dd>
          </dl>
          <button
            type="button"
            name="login_btn"
            className="login_btn"
            onClick={() => handleClickLoginBtn()}
          >
            ログイン
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
